<template>
  <v-dialog v-model="dialog" persistent :max-width="maxWidth">
    <v-card class="confirm-modal">
      <v-card-title>
        <span class="confirm-modal__title" v-html="question"></span>
      </v-card-title>
      <v-card-text>
        <div class="confirm-modal__body">
          <div class="d-flex justify-center">
            <v-btn
              color="primary"
              depressed
              @click="$emit('closeModal')"
              small
              :class="[reverse ? 'order-1' : '', 'mx-1']"
            >
              {{ notAgreeText }}
            </v-btn>
            <v-btn
              color="info"
              depressed
              @click="
                $emit('confirmModalAgree');
                $emit('closeModal');
              "
              class="mx-2"
              small
            >
              {{ agreeText }}
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  props: {
    show: {
      type: Boolean,
    },
    question: {
      type: String,
      default: () => "Es-tu sûr ?",
    },
    maxWidth: {
      type: String,
      default: () => "430",
    },
    reverse: {
      type: Boolean,
      default: () => false,
    },
    notAgreeText: {
      type: String,
      default: () => "Non",
    },
    agreeText: {
      type: String,
      default: () => "Oui",
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(newVal) {
        this.dialog = newVal;
      },
    },
  },
};
</script>

<style src="./ConfirmDialog.scss" lang="scss" scoped></style>